<template>
  <van-tabs v-model="activeName" color="#387FF5">
    <van-tab name="0" title="问题详情">
      <div class="info">
        <top-bar :title="'指南详情'" :left="true"></top-bar>
        <div class="infoBox">
          <div class="title">
            <p>{{ detailData.title }}</p>
            <span>{{ detailData.createTime }}</span>
          </div>
          <van-divider :hairline="false" />
          <div class="content" v-html="detailData.content"></div>
          <van-popup v-model="filesShow" position="bottom" :style="{ padding: '40px 0' }"
            v-if="detailData.files && detailData.files.length > 0">
            <div v-for="item, index in detailData.files" :key="index" :id="'url' + index"
              :data-clipboard-text="item.url"
              style="color: #387FF5; font-size: 16px; margin-bottom: 12px; margin-left: 40px;"
              @click="copyUrl(`url${index}`)">{{ item.name }}</div>
          </van-popup>
          <div class="form-btn-view">
            <van-row class="btns">
              <van-col span="24" v-if="detailData.files && detailData.files.length > 0">
                <div style="color: #387FF5; font-size: 16px; text-align: center; margin-bottom: 12px;"
                  @click="filesShow = true">获取文件链接或下载地址</div>
              </van-col>
              <van-col span="24">
                <van-button type="info" size="large" round @click="consult">我要咨询</van-button>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>
    </van-tab>
    <van-tab name="1" title="咨询列表">
      <div class="list-info">
        <div class="filterBar">
          <van-row style="background-color: #fff;">
            <van-col :span="6">
              <div @click="faqsUserShow = !faqsUserShow" :style="{color:faqsUserShow?'#387FF5' : '#666666' }">
                {{faqsUser}}
                <img :src="require(`@/assets/img/${faqsUserShow?'pull-down-select' :'pull-down'}.png`)" alt="" class="pull-icon">
              </div>
              <van-popup v-model="faqsUserShow" position="bottom">
                <van-picker
                  title="请选择"
                  show-toolbar
                  :columns="[{label: '全部'}, {label: '我提问的'}]"
                  value-key="label"
                  @confirm="onConfirmUser"
                  @cancel="faqsUserShow = false"
                />
              </van-popup>
            </van-col>
            <van-col :span="18">
              <form action="/">
                <van-search v-model="searchValue" show-action placeholder="请输入搜索关键词" @search="onSearch" @cancel="onCancel" />
              </form>
            </van-col>
          </van-row>
        </div>

        <div class="cont">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
            v-if="dataList.length > 0" offset="10">
            <van-cell class="list-item" style="margin: 10px 0" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
              <div class="item">
                <p class="itemTitle">{{ item.questionTitle }}</p>
                <p class="itemOrg" :style="item.hasAnswer == 0 ? 'color: #f00' : 'color:#387FF5;'"><span>{{ item.hasAnswer === 0 ? '待回复' : '已回复' }}</span></p>
                <span>时间: {{ item.createTime }}</span>
              </div>
            </van-cell>
          </van-list>
          <van-empty description="没有数据哦" v-else />
        </div>
      </div>
    </van-tab>
  </van-tabs>
</template>

<script>
import Clipboard from 'clipboard'
import topBar from '@/components/topBar/topBar'

import { mapState } from 'vuex'
export default {
  data() {
    return {
      activeName: '0',
      filesShow: false,
      detailData: {},
      searchValue: '',
      dataList: [],
      loading: false,
      finished: false,
      page: 0,
      limit: 10,
      faqsUser: '全部',
      faqsUserShow: false
    }
  },
  watch: {
    activeName(val) {
      if (val == 1) {
        this.getDataList()
      }
    }
  },
  computed: { ...mapState(['guideId']) },
  components: {
    topBar
  },
  methods: {
    getDetail() {
      console.log(this.guideId);
      this.$http({
        url: this.$http.adornUrl('/wxapp/guide/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.guideId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.detailData = data.guide
          this.$toast.clear()
        } else {
          console.log(data);
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    getDataList() {
      this.page++
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/guide/faqs/list'),
        method: 'post',
        params: this.$http.adornParams({
          isHeat: 1,
          guideId: this.guideId,
          page: this.page,
          limit: this.limit,
          title: this.searchValue,
          orgId: this.$orgId,
          createUserId: this.faqsUser == '全部' ? '' : this.$userId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
      })
    },
    copyUrl(id) {
      var clipboard = new Clipboard(`#${id}`)
      clipboard.on('success', () => {
        this.$toast.success('复制成功！')
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$toast.fail('不支持自动复制')
        clipboard.destroy()
      })
    },
    onConfirmUser (e) {
      this.faqsUserShow = false
      this.faqsUser = e.label
      this.page = 0
      this.dataList = []
      this.finished = false
      this.getDataList()
    },
    onSearch() {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.finished = false
      this.getDataList()
    },
    consult() {
      this.$router.push('/consult-add')
    },
    getInfo (id) {
      this.$router.push({path: '/consult-info', query: {id}})
    }
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDetail()
  }
}
</script>
<style>
a {
  color: rgb(4, 50, 255);
}
</style>
<style lang="scss" scoped>
.van-divider {
  border-color: #000;
}

.info {
  margin-top: 20px;
  padding: 0 20px;
  background-color: #f5f5f5;

  .infoBox {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;

    .title {
      p {
        font-size: 36px;
        margin: 10px 0 20px;
      }

      span {
        font-size: 24px;
        color: #999;
      }
    }

    .content {
      font-size: 30px;
    }
  }
}
.cont {
  margin-top: 30px;
  padding: 0 20px 20px;
  background-color: #f5f5f5;

  .item {
    padding: 20px;
    overflow: hidden;

    .itemTitle {
      display: inline-block;
      width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
    }

    .itemOrg {
      float: right;
    }

    p {
      margin: 0;
      font-size: 30px;

      span {
        font-size: 26px;
      }
    }

    span {
      display: block;
      font-size: 22px;
    }
  }
}
</style>
